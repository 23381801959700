<template>
	<div class="statistics__column">
		<AppText
			size="16"
			line-height="21"
			weight="600"
			class="mb-20"
		>{{ $t('top5SoldProductCategories') }}
		</AppText>
		<!-- <form-label v-model="type" @input="filter" :list="types" component-type="dropdown" class="mb-20 dropdown-content" :title="$t('type')"></form-label> -->

		<div class="table-block statistics-table">
			<table>
				<thead>
				<tr>
					<th class="text-bold">{{ $t('categoryName') }}</th>
					<th class="text-bold">{{ $t('amountShort') }}</th>
					<th class="text-bold">{{ $t('summa') }}</th>
				</tr>
				</thead>
				<tbody>
					<template v-if="data.length > 0">
						<tr v-for="(item, idx) in data"
							:key="idx"
						>
							<td>{{ item.key ? item.key : item.categoryName }}</td>
							<td class="text-center">{{ item.quantity }}</td>
							<td class="text-center">{{ (item.vsePrice ? item.vsePrice : item.price) | price }}</td>
						</tr>
					</template>
					<tr v-else>
						<td colspan="4" class="text-center">{{ $t('noData') }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script>
import AppText from '@/components/shared-components/AppText';
import FormLabel from '@/components/shared-components/FormLabel';
import i18n from '../../../plugins/vue-i18n-config';
import ClientService from '@/services/api/client-service';

export default {
	name: "TopProductCategories",
	props: {
		startDate: {
			type: Date,
			required: true
		},
		endDate: {
			type: Date,
			required: true
		}
	},
	components: {
		AppText,
		FormLabel
	},
	data() {
		const types = [
			{ value: 1, name: i18n.t('shop'), method: 'getShopTop5' },
			{ value: 2, name: i18n.t('auction'), method: 'getAuctionTop5' },
			{ value: 3, name: i18n.t('selection'), method: 'getSelectionTop5' },
			{ value: 4, name: i18n.t('tender'), method: 'getTenderTop5' }
		];
		return {
			type: 1,
			types,
			data: []
		}
	},
	mounted() {
		this.load();
	},
	methods: {
		load(methodName = 'getShopTop5') {
			const query = {
				startDate: this.startDate,
				endDate: this.endDate,
			};

			ClientService[methodName](query)
			.then(response => {
				if (response.data?.result?.data) {
					this.data = response.data.result.data;
				} else {
					this.$notify({type: 'error', text: this.$t('error')});
				}
			}, error => console.log(error))
		},
		filter(e) {
			const serviceName = this.types.find(item => item.value === e);
			this.load(serviceName.method);
		}
	}
}
</script>
<style lang="scss" scoped>
	@media(max-width: 768px) {
		.statistics-table {
			table {
				width: 100%;
			}
		}
	}

	@media(max-width: 450px) {
		.statistics-table {
			table {
				width: max-content;
			}
		}
	}
</style>