<template>
	<div class="statistics__column d-flex align-center justify-content-center">
		<div class="apex-chart">
			<AppText class="d-flex justify-content-center mb-20" size="16" line-height="21" weight="700">{{
				$t("transactionAmount")
			}}</AppText>
			<template v-if="series === null || Array.isArray(series) === false">
				<VueApexCharts
					type="pie"
					:width="isDesktopMedium ? '300px' : '300px'"
					height="450px"
					:options="defaultData.options"
					:series="defaultData.series"
					:key="updateChart"
				/>
			</template>
			<template v-if="series !== null && Array.isArray(series) && series.length">
				<VueApexCharts
					type="pie"
					:width="isDesktopMedium ? '300px' : '300px'"
					height="450px"
					:options="options"
					:series="series"
					:key="updateChart"
				/>
			</template>
		</div>
	</div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import AppText from "@/components/shared-components/AppText";
import ClientService from "@/services/api/client-service";
import i18n from "../../../plugins/i18n";

export default {
	name: "DealsSum",
	props: {
		startDate: {
			type: Date,
			required: true,
		},
		endDate: {
			type: Date,
			required: true,
		},
	},
	components: {
		VueApexCharts,
		AppText,
	},
	data() {
		return {
			series: null,
			options: {
				labels: [],
				colors: ["#1BBBED", "#38C59A", "#FFBB0D", "#FF7B42"],
				legend: {
					position: "bottom",
					fontSize: "14px",
					itemMargin: {
						horizontal: 15,
					},
					markers: {
						width: "8px",
						height: "8px",
						offsetX: "-8px",
					},
				},
			},
			data: null,
			updateChart: 0,
			defaultData: {
				options: {
					colors: ["#1BBBED"],
					labels: i18n.t("noData"),
					legend: {
						position: "bottom",
						fontSize: "14px",
						itemMargin: { horizontal: 0 },
						markers: { width: "8px", height: "8px", offsetX: "-8px" },
					},
				},
				series: [100],
			},
		};
	},
	mounted() {
		this.load();
	},
	methods: {
		load() {
			const query = {
				startDate: this.startDate,
				endDate: this.endDate,
			};

			ClientService.dealSumData(query).then(
				(response) => {
					if (response.data?.result) {
						this.setDiagramData(response.data.result);
					} else {
						this.$notify({ type: "error", text: this.$t("error") });
					}
				},
				(error) => console.log(error)
			);
		},
		setDiagramData(data) {
			this.series = [];
			let labels = [];

			for (let i in data) {
				this.series.push(Math.round(data[i] * 100) / 100);

				let label = i.replaceAll("Deal", "");
				label = label === "procent" ? this.$t("shop") : this.$t(label);
				labels.push(label);
			}

			this.options = {
				...this.options,
				labels,
			};
			this.updateChart++;
		},
	},
};
</script>
<style lang="scss" scoped>
.apex-chart {
	svg {
		path {
			stroke-width: 0;
		}
	}
}

.apexcharts-canvas {
	width: 100% !important;

	.apexcharts-svg {
		width: 100% !important;
	}
}
</style>
