<template>
  <div class="statistics__column">
    <AppText
      size="16"
      line-height="21"
      weight="600"
      class="mb-20"
    >{{ $t('procurementInfoByRegion') }}</AppText>
    <!-- <form-label v-model="type" @input="filter" :list="types" class="mb-20 dropdown-content" component-type="dropdown" :title="$t('type')"></form-label> -->
    <div class="table-block statistics-table">
      <table>
        <thead>
          <tr>
            <th>{{ $t('region') }}</th>
            <th>{{ $t('transactionAmount') }}</th>
            <th>{{ $t('amountShort') }}</th>
            <th>{{ $t('saving') }}</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="data.length > 0">
            <tr v-for="(item, idx) in data" :key="idx">
              <td>{{ item.key }}</td>
              <td>{{ item.vsePrice | price }}</td>
              <td>{{ item.quantity }}</td>
              <td>{{ item.savingPrice | price }}</td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="4" class="text-center">{{ $t('noData') }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import AppText from "@/components/shared-components/AppText";
import FormLabel from "@/components/shared-components/FormLabel";
import i18n from "../../../plugins/vue-i18n-config";
import ClientService from "@/services/api/client-service";

export default {
  name: "PurchasingInformation",
  props: {
    startDate: {
      type: Date,
      required: true,
    },
    endDate: {
      type: Date,
      required: true,
    },
  },
  components: {
    AppText,
    FormLabel,
  },
  data() {
    const types = [
      { value: 1, name: i18n.t("shop"), method: "purchasingInfoByShop" },
      { value: 2, name: i18n.t("auction"), method: "purchasingInfoByAuction" },
      {
        value: 3,
        name: i18n.t("selection"),
        method: "purchasingInfoBySelection",
      },
      { value: 4, name: i18n.t("tender"), method: "purchasingInfoByTender" },
    ];
    return {
      type: 1,
      types,
      data: [],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load(methodName = "purchasingInfoByShop") {
      const query = {
        startDate: this.startDate,
        endDate: this.endDate,
      };

      ClientService[methodName](query).then(
        (response) => {
          if (response.data?.result?.data) {
            this.data = response.data.result.data;
          } else {
            this.$notify({ type: "error", text: this.$t("error") });
          }
        },
        (error) => console.log(error)
      );
    },
    filter(e) {
      const serviceName = this.types.find((item) => item.value === e);
      this.load(serviceName.method);
    },
  },
};
</script>
<style lang="scss" scoped>
.statistics-table {
  th {
    background-color: #1faec1;
    color: #fff;
    font-family: "MazzardMMedium";
	font-weight: 500 !important;
	border-right-color: #45bacb !important;
  }
}
@media (max-width: 768px) {
  .statistics-table {
    table {
      width: 100%;
    }
  }
}

@media (max-width: 450px) {
  .statistics-table {
    table {
      width: max-content;
    }
  }
}
</style>