<template>
	<div class="statistics__column">
		<div class="apex-chart">
			<AppText class="mb-20" size="16" line-height="21" weight="700">
				{{ $t("dealAmount") }}
			</AppText>

			<VueApexCharts
				type="bar"
				width="100%"
				height="300px"
				:options="chartOptions"
				:series="AmountOfDealsValue"
			/>
		</div>
	</div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import AppText from "@/components/shared-components/AppText";
import ClientService from "@/services/api/client-service";
import i18n from "../../../plugins/i18n";

export default {
	name: "DealsAmount",
	props: {
		startDate: {
			type: Date,
			required: true,
		},
		endDate: {
			type: Date,
			required: true,
		},
	},
	components: {
		VueApexCharts,
		AppText,
	},
	data() {
		return {
			AmountOfDealsValue: [
				{
					data: [],
				},
			],
			chartOptions: {
				chart: {
					height: 200,
					width: "100%",
					type: "bar",
					events: {
						click: function (chart, w, e) {
							// console.log(chart, w, e)
						},
					},
				},
				colors: ["#3B75CC", "#FFC700", "#FF9040", "#341F47"],
				plotOptions: {
					bar: {
						columnWidth: "45%",
						distributed: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				legend: {
					show: false,
				},
				xaxis: {
					categories: [],
					labels: {
						style: {
							fontSize: "12px",
						},
					},
				},
			},
		};
	},
	mounted() {
		this.load();
	},
	methods: {
		load() {
			const query = {
				startDate: this.startDate,
				endDate: this.endDate,
			};

			ClientService.dealsAmountData(query).then(
				(response) => {
					if (response.data?.result) {
						this.setDiagramData(response.data.result);
					} else {
						this.$notify({ type: "error", text: this.$t("error") });
					}
				},
				(error) => console.log(error)
			);
		},
		setDiagramData(data) {
			this.AmountOfDealsValue = [{ data: [] }];
			let labels = [];

			for (let i in data) {
				this.AmountOfDealsValue[0].data.push(Math.round(data[i] * 100) / 100);

				let label = i.replaceAll("Quantity", "");
				label = label === "deal" ? this.$t("shop") : this.$t(label);
				labels.push(label);
			}

			const x_axis = {
				categories: labels,
				labels: {
					style: {
						fontSize: "12px",
					},
				},
			};

			this.chartOptions = {
				...this.chartOptions,
				xaxis: x_axis,
			};
		},
	},
};
</script>
<style lang="scss" scoped>
.apex-chart {
	svg {
		path {
			stroke-width: 0;
		}
	}
}

.apexcharts-canvas {
	width: 100% !important;

	.apexcharts-svg {
		width: 100% !important;
	}
}
</style>
