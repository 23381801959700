<template>
  <main class="page-container container">
    <div class="section__top mb-30" :class="[isMobile ? 'flex-wrap' : '', isMobile ? 'w-100' : '']">
      <div class="section__top-left" :class="isMobile ? 'mb-20' : ''">
        <AppText
          :size="isMobileSmall ? 20 : isMobile  ? 24 : 26"
          weight="600"
          :line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
        >{{ $t('allSections') }}</AppText>
      </div>

      <div class="section__top-right d-flex align-center" :class="isMobileMedium ? 'd-block' : ''">
        <AppText
          size="14"
          line-height="16"
          class="color-text mr-15"
          :class="isMobileMedium ? 'mb-10' : ''"
        >{{ $t('showPeriod') }}:</AppText>

        <BlockWrap :count="isMobileMedium ? 1 : 2" offset-y="15" offset-x="15">
          <AppDatePicker v-model="filter.startDate" class="datepicker-dark mr-20" style="width: 150px" />
          <AppDatePicker v-model="filter.endDate" class="datepicker-dark" style="width: 150px" />
        </BlockWrap>
      </div>
    </div>

    <div class="section__body" :class="isMobile ? 'mb-50' : 'mb-80'">
      <BlockWrap :count="isMobile ? 1 : 2" offset-x="20" offset-y="40">
        <purchasing-information
          :start-date="filter.startDate"
          :end-date="filter.endDate"
          :key="`purchase${updateComponent}`"
        ></purchasing-information>

        <deals-sum
          :start-date="filter.startDate"
          :end-date="filter.endDate"
          :key="`deals${updateComponent}`"
        ></deals-sum>
      </BlockWrap>
      <div :class="isMobileMedium ? 'mb-30' : 'mb-50'"></div>
      <BlockWrap :count="isMobile ? 1 : 2" offset-x="20" offset-y="40">
        <deals-amount
          :start-date="filter.startDate"
          :end-date="filter.endDate"
          :key="`deals_amount${updateComponent}`"
        ></deals-amount>

        <top-product-categories
          :start-date="filter.startDate"
          :end-date="filter.endDate"
          :key="`top${updateComponent}`"
        ></top-product-categories>
      </BlockWrap>
    </div>
  </main>
</template>

<script>
import AppText from "../../components/shared-components/AppText";
import BlockWrap from "../../components/shared-components/BlockWrap";
import AppDatePicker from "../../components/shared-components/AppDatepicker";

import PurchasingInformation from "@/components/pages/statistics/PurchasingInformation";
import DealsSum from "@/components/pages/statistics/DealsSum";
import DealsAmount from "@/components/pages/statistics/DealsAmount";
import TopProductCategories from "@/components/pages/statistics/TopProductCategories";

export default {
  name: "StatisticsPage",
  components: {
    AppDatePicker,
    BlockWrap,
    AppText,
    PurchasingInformation,
    DealsSum,
    DealsAmount,
    TopProductCategories,
  },
  data() {
    return {
      filter: {
        startDate: null,
        endDate: null,
      },
      updateComponent: 0,
    };
  },
  created() {
    this.setDefaultDate();
  },
  methods: {
    setDefaultDate() {
      let today = new Date();
      const date = new Date();
      const month = today.getMonth();

      date.setMonth(date.getMonth() - 1);
      while (date.getMonth() === month) {
        date.setDate(date.getDate() - 1);
      }

      this.filter.startDate = date;
      this.filter.endDate = today;
    },
    updateComponents(field) {
      if (this.filter[field]) {
        this.updateComponent++;
      }
    },
  },
  watch: {
    "filter.startDate": function (val) {
      this.updateComponents("endDate");
    },
    "filter.endDate": function (val) {
      this.updateComponents("startDate");
    },
  },
};
</script>
